import data from '../data.json';
//  import { GiSkills } from 'react-icons/gi';
import {
  DiDatabase,
  //  DiReact,
  DiCode,
  DiCssTricks
} from 'react-icons/di';
//  import { BsFillBootstrapFill } from 'react-icons/bs';
//  import { FaLanguage } from 'react-icons/fa';
import { SiHandshake } from 'react-icons/si';
//  import { GrTechnology } from 'react-icons/gr';
import { SiIpfs } from 'react-icons/si';
import { GoKeyboard } from 'react-icons/go';
import Skills from './Skills';


export default function SkillSets(props) {

  const skillset = [];
  const frameworks = [];
  const languages = [];
  const technologies = [];
  const databases = [];
  const web3 = [];

  for ( const project of Object.values(data) ) {

    for ( const skill of project.skills ) {
      if (!skillset.includes(skill)) {
        skillset.push(skill);
      }
    }

    for ( const item of project.frameworks ) {
      if (!frameworks.includes(item)) {
        frameworks.push(item);
      }
    }

    for ( const item of project.languages ) {
      if (!languages.includes(item)) {
        languages.push(item);
      }
    }

    for ( const item of project.technologies ) {
      if (!technologies.includes(item)) {
        technologies.push(item);
      }
    }

    for ( const item of (project?.databases || []) ) {
      if (!databases.includes(item)) {
        databases.push(item);
      }
    }

    for ( const item of (project?.web3 || []) ) {
      if (!web3.includes(item)) {
        web3.push(item);
      }
    }

  };





  return (
    <div className='mt-5'>
      <Skills
        type='human skills'
        Icon={SiHandshake}
        skills={skillset}
      />
      <Skills
        type='web 3/decentralized web'
        Icon={SiIpfs}
        skills={web3}
      />
      <Skills
        type='frameworks and libraries'
        Icon={DiCssTricks}
        skills={frameworks}
      />
      <Skills
        type='languages'
        Icon={DiCode}
        skills={languages}
      />
      <Skills
        type='other technologies'
        Icon={GoKeyboard}
        skills={technologies}
      />
      <Skills
        type='databases'
        Icon={DiDatabase}
        skills={databases}
      />
    </div>
  );
}
