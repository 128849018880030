
export default function ProjectSkills(props) {
  const {
    type,
    skills,
  } = props;

  if (!skills || skills.length === 0) {
    return null;
  }

  return (
    <div className='py-1'>{type}:
    {
      skills.map( (skill, i) => {
        return (
          <span
            key={i}
            className={`
              mx-2
              dark:bg-red-900
              font-medium
              bg-red-100
              p-2
              rounded-lg
            `}
          >
            {skill}
          </span>
        );
      })
    }
    </div>
  )
}
