import data from '../data.json';
import hl7 from '../images/hl7.png';
import mailbridge from '../images/mailmask.png';
import measurehub from '../images/measurehub.png';
import ndu from '../images/ndu.png';
import openmrs from '../images/openmrs.png';

export default function Projects(props) {

  const Images = {
    hl7,
    mailbridge,
    measurehub,
    ndu,
    openmrs,
  };

  const { setProject } = props;


  const Items = Object.keys(data).map( (key, index) => {
    const project = data[key];

    const {
      name,
      description,
      img,
      //skills,
      url,
    } = project;

    const Link = (
      <a
        href={url}
        target='_blank'
        rel='noreferrer'
        className={`
          text-green-500
          hover:text-green-600
          hover:underline
          font-semibold
          text-xs
          px-5
        `}
      >
        {url}
      </a>
    );


    return (
      <div
        key={index}
        className={`
          font-sans
          py-5
          rounded-lg
          dark:bg-gray-800
          bg-gray-200
          text-base
          font-bold
          opacity-90
          hover:opacity-100
          cursor-pointer
          shadow-lg

        `}
        onClick={() => setProject({...project, key}) }
      >
        <span className='px-5'>
          {name} { url ? Link : null }
        </span>
        <img
          src={Images[key]}
          alt={`screenshot of ${name}`}
          className={`
            my-2
            inline
          `}
        />
        <div
          className={`
            px-5
          `}
        >
          {description}
        </div>
        <div className='grid grid-cols-4 gap-1 px-5'>
        </div>
      </div>
    );
  });


  return (
    <div className='mt-5'>
      <div className='grid sm:grid-cols-2 gap-4 grid-cols-1'>
        {Items}
      </div>
    </div>
  );
}
