//  import logo from './logo.svg';
import './index.css';
import './App.css';
import Projects from './components/Projects.js';
import ProjectDetails from './components/ProjectDetails.js';
import SkillSets from './components/SkillSets.js';
import { BsFillSunFill, BsFillMoonFill } from 'react-icons/bs';
import { useState, useEffect } from 'react';

function App() {

  const [ theme, setTheme ] = useState(true);
  const [ project, setProject ] = useState();

  useEffect(() => {
    document.body.classList.toggle('dark', theme);
  },[theme]);

  const ThemeToggleIcon = theme
    ? BsFillSunFill
    : BsFillMoonFill

  const Home = (
    <div
      className='grid grid-cols-12'
    >
      <div className='sm:col-span-3 col-span-12'>
        <SkillSets />
      </div>
      <div className='sm:col-span-9 col-span-12'>
        <Projects setProject={setProject}/>
      </div>
    </div>
  )
  return (
    <div
      className={`
          h-full
          w-screen
          bg-gradient-to-b
          dark:from-black
          dark:via-gray-900
          dark:to-gray-800
          dark:text-white
          from-white
          via-gray-200
          to-gray-100
          text-black
          text-xl
          font-sans
          flex
          items-center
          justify-center
      `}
    >
      <div className='w-11/12'>
        <div className={`
            border-b
            border-1
            dark:border-gray-50
            border-gray-900
            py-5
            font-semibold
            flex
            justify-center
        `}>
          chris millet

          <ThemeToggleIcon
            className={`
              cursor-pointer
              hover:text-green-500
              m-1
            `}
            onClick={() => setTheme(!theme)}
          />


        </div>
        {
          project
            ? <ProjectDetails project={project} setProject={setProject} />
            : Home
        }
      </div>

    </div>

  );
  /*
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
  */
}

export default App;
